import React, { useEffect } from 'react'
import './App.css'
import Footer from './Footer';
import Torba1 from './images/torbeprodukt.png';
import Ceker from './images/cekeriprodukt.png'
import Bubreg from './images/bubreziprodukt.png'
import Neseser from './images/neseseriprodukt.png'
import Ruksak from './images/ruksakprodukt.png'
import MegaTorba from './images/megasizeprodukt.png'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';


export default function Products() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
   <>
   

<Container fluid>
  <Row className="justify-content-center card-cover px-2">
    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={12}>
      <h2>PROIZVODI</h2>
    </Col>
    
    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/ruksaci">
    <img className='image-container img-fluid' src={Ruksak}></img>
    </Link>
    </Col>

    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/torbe">
    <img className='image-container img-fluid' src={Torba1}></img>
    </Link>
    </Col>

    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/cekeri">
    <img className='image-container img-fluid' src={Ceker}></img>
    </Link>
    </Col>

    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/bubrezi">
    <img className='image-container img-fluid' src={Bubreg}></img>
    </Link>
    </Col>

    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/neseseri">
    <img className='image-container img-fluid' src={Neseser}></img>
    </Link>
    </Col>

    <Col className="pt-3 text-center overflow-hidden " xs={10} md={12} lg={2}>
    <Link to="/megasizetorbe">
    <img className='image-container img-fluid' src={MegaTorba}></img>
    </Link>
    </Col>
    
  </Row>
</Container>


<Footer />
   </>
  )
}
