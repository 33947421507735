import React from 'react'
import Main from './Main'
import Footer from './Footer'
import GridGallery from './GridGallery'


export default function Home() {

  
  return (
    <>
    
      <Main />
      <GridGallery />
      <Footer />
    
    </>
  )
}
