import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';


const AdminLogin = ({ setAuthenticated }) => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const correctPassword = 'selmaiamela123';

  const handleLogin = () => {
    if (password === correctPassword) {
      setAuthenticated(true);
      navigate('/admin');
    } else {
      alert('Incorrect password!');
    }
  };

  return (
       <Container>
       <Row className="justify-content-center mb-4">
        <Col className="pt-3 text-center">
        <h2>Admin Login</h2>
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Enter password"
      />
      <button onClick={handleLogin}>Login</button>
        </Col>
        </Row>
        </Container>
     
  );
};

export default AdminLogin;
