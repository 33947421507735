import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

const Admin = () => {
  const [selectedCategory, setSelectedCategory] = useState("bubrezi"); // Default category
  const [products, setProducts] = useState([]);
  const [newPrices, setNewPrices] = useState({}); // Store the updated prices

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsCollectionRef = collection(db, selectedCategory);
        const productsSnapshot = await getDocs(productsCollectionRef);
        const filteredData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(filteredData);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
    };

    if (selectedCategory) {
      fetchData();
    }
  }, [selectedCategory]);

  const handlePriceChange = (id, price) => {
    setNewPrices({ ...newPrices, [id]: price });
  };

  const handlePriceUpdate = async (id) => {
    const productRef = doc(db, selectedCategory, id);
    try {
      await updateDoc(productRef, {
        cijena: newPrices[id],
      });
      alert("Cijena uspjesno promijenjena!");
      setProducts(products.map(item => item.id === id ? { ...item, cijena: newPrices[id] } : item));
    } catch (error) {
      console.error("Gresko prilikom pokusaja promjene cijene: ", error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col className="pt-3 text-center"  xs={6} md={5} lg={2}>
          <h2>Admin Panel</h2>
          
          <Form.Group controlId="categorySelect">
            <Form.Label>Izaberi kategoriju</Form.Label>
           
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="bubrezi">Bubrezi</option>
              <option value="cekeri">Cekeri</option>
              <option value="megasizetorbe">Megasizetorbe</option>
              <option value="neseseri">Neseseri</option>
              <option value="ruksaci">Ruksaci</option>
              <option value="oversizedtorbe">Oversizedtorbe</option>
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {products.map((product) => (
            <Col xs={6} md={5} lg={2}>
              <Card className='mb-4'>
                <Card.Img variant="top" src={product.fotografija} />
                <Card.Body style={{margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Card.Title>{product.naziv}</Card.Title>

                </Card.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Cijena:</Form.Label>
                  <Form.Control
                    type="number"
                    value={newPrices[product.id] || product.cijena}
                    onChange={(e) => handlePriceChange(product.id, e.target.value)}
                  />
                </Form.Group>
              </Form>
              <Button size='sm' variant='outline-primary' className='mt-2' onClick={() => handlePriceUpdate(product.id)}>Promijeni cijenu</Button>
              </Card>

            </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Admin;
