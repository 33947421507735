import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import Footer from './Footer'
import { db } from './config/firebase';
import { getDocs, collection, updateDoc, doc } from 'firebase/firestore';
import { Button, Card, Container, Row, Col} from 'react-bootstrap';
import ModalMegaTorbe from './ModalMegaTorbe';
import { CartContext } from './CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import Collapse from 'react-bootstrap/Collapse';



export default function MegaTorbe() {
    const [megaBags, setMegaBags] = useState([]);
    const productsCollectionRef = collection(db, "megasizetorbe");
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { addToCart } = useContext(CartContext);
    const [addedToCart, setAddedToCart] = useState([]);
    const [likedMegaTorbe, setLikedMegaTorbe] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const getMegaBags = async () => {
          try {
          const data = await getDocs(productsCollectionRef);
          const filteredData = data.docs.map((doc) => {
            const { likes } = doc.data();
            return {
              id: doc.id,
              ...doc.data(),
              likes: likes || 0,
            };
          });
          setMegaBags(filteredData);
          console.log(filteredData)
          } catch (err) {
          console.log(err);
          }
        };
    
        getMegaBags();
      }, [])
    
    
      const handleProductClick = (megaTorba) => {
        setSelectedProduct(megaTorba);
        setShowModal(true);
      };
    
      const handleAddToCart = (megaTorba) => {
        addToCart(megaTorba);
        setAddedToCart([...addedToCart, megaTorba]);
      };
    
      const handleIncrementLikes = async (megaTorba) => {
        if (likedMegaTorbe.includes(megaTorba.id)) {
          return;
        }
    
        const updatedMegaBags = megaBags.map((megaBag) => {
          if (megaBag.id === megaTorba.id) {
            const updatedMegaBag = {
              ...megaBag,
              likes: megaBag.likes + 1,
            };
            return updatedMegaBag;
          }
          return megaBag;
        });
      
        try {
          const megaBagRef = doc(db, 'megasizetorbe', megaTorba.id);
          await updateDoc(megaBagRef, {
            likes: megaTorba.likes + 1,
          });
          setMegaBags(updatedMegaBags);
          setLikedMegaTorbe([...likedMegaTorbe, megaTorba.id]);
    
        } catch (err) {
          console.log(err);
        }
      };


  return (
    <>
        <Container fluid>
    <Row className="justify-content-center">
      <Col className="pt-3 text-center overflow-hidden ">
      <Button
      variant='dark'
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        Više o mega size torbama
      </Button>
      <Collapse in={open}>
        <div className='bg-light p-3 mt-2' id="example-collapse-text">
        Torbejarabi  MEGASIZE torba predstavlja spoj funkcionalnosti i moderne estetike, idealna za one koji traže više od svoje torbe. Karakteriše je klasična jednobojna paleta, s akcentom na jedinstvene i netipične boje koje dodaju posebnost svakom izgledu. Izrađena od visokokvalitetne eko-kože, ova torba ne samo da odražava ekološku svijest, već i obećava izdržljivost i stilski integritet kroz vreme. Unutar prostrane unutrašnjosti MEGASIZE-a nalazi se dodatna mala torbica, savršena za čuvanje esencijalnih sitnica, olakšavajući vam pronalazak ključeva, telefona ili novčanika u tren oka. Ova pametna organizacija prostora čini MEGASIZE idealnim izborom ne samo za svakodnevne obaveze, već i za šoping avanture, gdje njena prostranost dolazi do punog izražaja. Dizajnirana s naglaskom na udobnost, MEGASIZE je laka za nošenje, bilo da je prebačena preko ramena ili držana u ruci. Njene čvrste ručke osiguravaju udobnost čak i kad je torba puna, a njena otpornost na svakodnevno korištenje čini je pouzdanim pratiteljem u svim vašim pustolovinama. Bilo da ste na putu do posla, u shoppingu ili na putovanju, MEGASIZE je torba koja vas neće iznevjeriti, kombinujući praktičnost i elegantan dizajn za život u pokretu.

        </div>
      </Collapse>
      </Col>
    </Row>
      <Row className="justify-content-center">
      
      {megaBags.map((megaBag, h) => (
        
        <Col key={h} xs={6} md={5} lg={2} className="pt-3 text-center overflow-hidden ">

        <Card className="pt-3 text-center overflow-hidden card-cover">
          <Card.Img className='card-cover3' onClick={() => handleProductClick(megaBag)} variant="top" src={megaBag.fotografija} />

          <Card.Body style={{margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Card.Title>{megaBag.naziv}</Card.Title>
            <Card.Text style={{margin: '5px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

              <strong>{megaBag.cijena} KM</strong>
              
            </Card.Text>

            <Button style={{ borderRadius: '0', borderColor: '#d9d9d9' }} onClick={() => handleProductClick(megaBag)} size="sm" variant='outline-dark' className='card-cover2'>Pogledaj</Button>
          
            <Button onClick={() => handleAddToCart(megaBag)} style={{ marginTop: '5px', borderRadius: '0', borderColor: '#d9d9d9' }} size="sm" className='card-cover2' variant='outline-dark' disabled={addedToCart.includes(megaBag)}>{addedToCart.includes(megaBag) ? 'Dodano u korpu' : 'Dodaj u Korpu'}</Button>
            <Button
              onClick={() => handleIncrementLikes(megaBag)}
              style={{ border: "none", position: "absolute", top: "3px", right: "3px", backgroundColor: '#e4dae999'}}
              size="sm"
              variant="outline-dark"
              className='card-cover2'
              disabled={likedMegaTorbe.includes(megaBag.id)}
            >
              <FontAwesomeIcon icon={faHeart} />
              <div>
              {megaBag.likes}
              </div>
            </Button>
        </Card.Body>
          
        </Card>

        </Col>
        ))}
      </Row>
    </Container>    

    <Footer/>


    <ModalMegaTorbe
      addedToCart={addedToCart}
      addToCart={handleAddToCart}
      megaBag={selectedProduct}
      showModal={showModal}
      onClose={() => setShowModal(false)}
    />
    </>
  )
}
